'use strict';

const formValidation = require('base/components/formValidation');
const createErrorNotification = require('base/components/errorNotification');
const baseLogin = require('base/login/login');

const { queryFirst, addClass, removeClass, loadInput } = require('../domUtil');
const { formatPhoneNumber, getItemFromLocalStorage } = require('../util');
const registrationFormPhone = queryFirst('.registration-form-phone');
const loyaltyGuestReferralModal = queryFirst('#guest-friend-referral-popup');
const $body = $('body');

window.addEventListener('load', (event) => {
    const formElement = queryFirst('.change-password-form');
    const referrerID = getItemFromLocalStorage('REFERRED_ID');
    if (referrerID && formElement) {
        loadInput(referrerID, formElement);
    }
    if (loyaltyGuestReferralModal) {
        $(loyaltyGuestReferralModal).modal('show');
    }
});

module.exports = {
    ...baseLogin,

    login: function () {
        /*
        This event finishes the form.login submission response processing with redirect
        */
        $(document).one('login:success:gtm', function(event, data) {
            if (data?.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        });
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        // redirect moved to event "login:success" processor
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        form.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        /*
        This event finishes the form.registration submission response processing with redirect
        */
        $(document).one('register:success:gtm', function(event, data) {
            if (data?.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        });
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $body.spinner().start();
            form.trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $body.spinner().stop();
                    if (data.success) {
                        form.trigger('register:success');
                        // redirect moved to event "register:success" processor
                    } else {
                        formValidation(form, data);
                        form.trigger('register:error');
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $body.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        const resetPasswordDialog = $('#requestPasswordResetModal');

        resetPasswordDialog.on('hidden.bs.modal', function () {
            removeClass(queryFirst('.password-reset-section', this), 'request-success');
            const emailEl = document.getElementById('reset-password-email');
            emailEl.value = '';
            removeClass(emailEl, 'is-invalid');
        });

        $('.reset-password-form').on('submit', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        // LP Customization start
                        addClass(queryFirst('.reset-password-request-modal .password-reset-section'), 'request-success');
                        // LP Customization end
                    }
                },
                error: function () {
                    form.spinner().stop();
                    resetPasswordDialog.modal('hide').one('hidden.bs.modal', () => {
                        $body.trigger('csrf:error');
                    });
                }
            });

            return false;
        });
    },
    handleLogin: function () {
        const loginBtnEl = queryFirst('.account-login-btn');
        const $loginFormEl = $('form.login');
        $loginFormEl.on('login:submit', function () {
            loginBtnEl.disabled = true;
        });

        $loginFormEl.on('login:error', function () {
            loginBtnEl.disabled = false;
        });
    }
};

formatPhoneNumber(registrationFormPhone);
